@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "react-toastify/dist/ReactToastify.css";
@import "animate.css";
@import "./Colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap");

@font-face {
  font-family: "Gentona";
  src: url("../public/fonts/GentonaBook.otf");
}

@font-face {
  font-family: "Gentona Medium";
  src: url("../public/fonts/GentonaMedium.otf");
}

@font-face {
  font-family: "GentonaSemibold";
  src: url("../public/fonts/GentonaSemiBold.otf");
}

@font-face {
  font-family: "GentonaBold";
  src: url("../public/fonts/GentonaBookBold.otf");
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  background-color: black;
}

.main-body {
  height: calc(100svh - 56px);
  box-sizing: border-box;
}

.full-page {
  height: 100svh;
  width: 100%;
}


.sidemenu-container {
  padding: 0px !important;
}


.sidemenu-toggle-btn {
  position: absolute;
  margin: 0 auto;
  margin-left: 0px;
  top: 50%;

  .btn {
    border-radius: 20px;
  }
}

.showMenu {
  .sidemenu-toggle-btn {
    margin-left: -30px;
    z-index: 2;
  }
}

.home-cotainer {
  height: 100svh;
  width: 100svw;
}


.page-bdoy {
  overflow-y: auto;
  padding-top: 2rem;
}

.profile-wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  color: white;
  overflow-x: hidden;
}

.ai-bot {
  height: 100svh;
  padding: 1rem;
}