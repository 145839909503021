.container {
  z-index: 2;
  position: relative;
}

.hero-section {
  background: url("../../../public/assets/images/hero-1.jpg") no-repeat center center;
  background-size: cover;
  color: white;
  height: 65vh;

  a {
    text-decoration: none;
    color: #fff;
  }

  .hero-section-child {
    background-color: #0e0d1070;
    height: 100%;

    .title-section {
      padding-left: 1rem;

      h1 {
        font-size: 4rem;
        font-weight: bold;
        animation: fadeIn 2s ease-in-out;
      }

      p {
        font-size: 1.2rem;
        animation: fadeIn 4s ease-in-out;
      }
    }

    .left-content {
      display: none;
    }

    @media (min-width: 1200px) {
      .left-content {
        display: block;
      }
    }
  }
}

.description-section {
  font-size: 1rem;
  background: linear-gradient(45deg, #d8b5ff, #1eae98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: monospace;
  padding-top: 1rem;
  min-height: 250px;
}

@media (min-width: 1200px) {
  .hero-section {
    height: 54vh;
  }

  .hero-section-child {
    padding-top: 2rem;
  }

  .description-section {
    font-size: 1.5rem;
  }
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

/* Star animations */
.stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 500px 100px #fff, 600px 200px #fff, 700px 300px #fff,
    800px 400px #fff, 900px 500px #fff, 1000px 600px #fff, 1100px 700px #fff,
    1200px 800px #fff;
  animation: animStar 50s linear infinite;
}

.stars:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 500px 100px #fff, 600px 200px #fff, 700px 300px #fff,
    800px 400px #fff, 900px 500px #fff, 1000px 600px #fff, 1100px 700px #fff,
    1200px 800px #fff;
}

.skills-image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 280px;
    width: 280px;
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #090a0f;
  width: 3rem;
  z-index: 99999;
}

.skills-footer {
  display: flex;
  justify-content: space-between;
}

.card {
  margin-bottom: 2rem;
}

.exp-item {
  min-height: 100svh;
  background-size: cover;
}

.exp-child {
  background-color: rgb(0 0 0 / 76%);
  height: 100%;
  width: 100%;
  font-size: 1rem;
  padding: 2rem;
}

@media (min-width: 1200px) {
  .exp-item {
    height: 50vh;
  }

  .exp-child {
    font-size: 1.2rem;
  }
}

.exp-item {
  height: 50vh;
  background-size: cover;
}

.gradient-button {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  border: none;
  border-radius: 50px;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.gradient-button:hover {
  background: linear-gradient(45deg, #2575fc, #6a11cb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.gradient-button:active {
  transform: scale(0.98);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.gradient-button:focus {
  outline: none;
}

.gradient-button-sm {
  padding: 12px;
  width: 100%;
  display: block;
  text-align: center;
}

#skills {
  .skill-icon {
    color: #007bff;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 15px;
  }

  p {
    font-size: 1rem;
    color: #b1cfe9;
  }
}

.accordion-body {
  background: #0e121a;
  color: #fff;
}

@keyframes zoom-in-out {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.ask-assistant-btn {
  animation: zoom-in-out 1.5s ease-in-out infinite;
}

.paint-bg {
  background-image: url("../../../public/assets/images/paint-bg.png");
  background-color: #cccccc;
  padding: 2rem;
  margin: 2rem 0rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(255, 0, 150, 0.5);
}

.see-more {
  cursor: pointer;
  float: right;
  text-decoration: underline;
  font-size: 1rem;
}

.skills-card {
  border-radius: 1rem;
  border: 1px solid #fff;
  padding: 0.4rem;
  height: 100%;
}