.login-container {
  .card {
    padding: 2rem 1rem;
    width: 420px;
    margin: 0rem 1rem;
    .form-control {
      margin-top: 0.5rem;
    }
  }
  .center-container {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .login-title {
    margin-top: 5rem;
  }
  .signuplink {
    color: blue;
    font-weight: bold;
    cursor: pointer;
  }
  .signup-footer {
    margin-top: 2rem;
  }
  @media screen and (max-width: 570px) {
    .card {
      width: 100%;
    }
  }
  .login-title {
    .logo {
      display: flex;
      padding: 1rem 0rem;
      justify-content: center;
      align-items: center;
    }
  }
}
