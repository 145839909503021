.project-card {
  .card {
    background: linear-gradient(135deg, #09203f 0%, #537895 100%);
    color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .text-primary {
    color: #ffdd57 !important;
  }

  .lead {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .highlight {
    background: rgba(0, 0, 0, 0.5);
    color: #ffc107;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
  }

  .list-unstyled {
    margin-bottom: 1rem;
  }

  a {
    color: #fff;
  }
}
