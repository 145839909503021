.loader-conainer {
  display: flex;
  height: 100svh;
  width: 100svw;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .loader-fade {
    height: 100%;
    width: 100%;
    opacity: 0.4;
  }

  .spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.yash-ui-spinner {
  width: 30px;
  height: 30px;
  border-radius: 33px;
  padding: 5px;
}

.yash-ui-spinner-full {
  width: 50%;
  border-radius: 50%;
  height: auto;
}
