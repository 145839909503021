.chat-container {
  height: 100%;

  .chat-header {
    height: 60px;
    padding: 8px;
    display: flex;
    align-items: center;
  }

  .chat-body-item {
    height: calc(100% - 100px);
  }

  .session-expire {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10%;

    p {
      font-size: 24px;
      font-weight: bold;
      margin: 0 0 15px;
    }
  }
}

.chat-footer-item {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 2;

  .footer-content {
    padding: 1rem 0rem;
  }
}

.promptContainer {
  display: flex;
  align-items: center;

  .defaultMessageBox {
    resize: none;
    width: 100%;
    display: flex;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    outline: 0;
    border-radius: 40px;
    border: 1px solid #ececed;
    background: transparent;
    padding: 20px 84px 20px 24px;
    max-height: 200px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    box-shadow: none;
    height: 60px;
    background-color: aliceblue;
  }

  .promptBox {
    width: 100%;
    position: relative;
  }

  .prompt-btn-container {
    position: absolute;
    right: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .prompt-btn {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
      background-color: black;
      color: #fff;
    }

    .blackBackground {
      background-color: black;
    }
  }

  .audioAnimationPitch {
    padding-left: 66px !important;
  }

  .crossBtn {
    left: 40px;
    cursor: pointer;
    position: absolute;
    color: black;
  }
}

.conversation-container {
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;

  .intro-text {
    min-height: 100px;
    margin-bottom: 2rem;
  }

  .message {
    .user-icon {
      display: flex;
      justify-content: flex-end;

      img {
        height: 30px;
        width: 30px;
      }
    }

    .prompt-icon {
      display: flex;
      justify-content: flex-start;
    }

    .left {
      display: flex;
      justify-content: start;

      .message-content {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        color: #fff;
        margin: 10px 0;
        align-items: baseline;
        max-width: 95%;
        word-wrap: break-word;
        padding-left: 1rem;
      }
    }

    .right {
      display: flex;
      justify-content: end;

      .message-time {
        text-align: end;
      }

      .message-content {
        border: 0 solid #f3f6fb;
        border-radius: 40px;
        margin-top: 16px;
        box-shadow: 0 2px 34px 0 transparent;
        font-size: 18px;
        font-weight: 400;
        padding: 18px 24px;
        justify-content: center;
        gap: 16px;
        line-height: 140%;
        max-width: 95%;
        word-wrap: break-word;
      }
    }

    .message-time {
      display: flex;
      align-items: center;
      font-size: 12px;

      .spinner-border {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }

    .message-content {
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  .error-prompt {
    .prompt-icon {
      display: none;
    }
  }

  .btn-plain {
    border: none;

    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }
}

.sumarization-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  max-width: 90%;

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }

  li:last-child {
    border-bottom: none;

    .info-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;
    }

    .info-icon:hover {
      transform: scale(1.4);
    }
  }
}

.chat-info-container {
  max-height: 80svh;
  overflow: auto;

  .card {
    margin-bottom: 10px;
  }

  .json-viewer {
    height: 100%;
  }
}

.chat-container-wrapper {
  height: 100%;
  position: relative;
}

.guZdik {
  max-width: 260px !important;
  min-width: 240px !important;
}
