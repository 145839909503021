.frequency-bars {
    display: flex;
    align-items: center;
    justify-content: center;

    .dot {
        max-height: 100% !important;
    }
}

.bars-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}