.sideMenu {
  height: 100%;
  .list-group {
    border-radius: 0px;
  }
  .list-group-item {
    cursor: pointer;
  }
  .nav-submenu {
    list-style-type: none;
    padding-left: 0px;
    li {
      padding: 0.4rem;
      a {
        text-decoration: none;
        color: inherit;
        display: block;
      }
    }
  }
}
