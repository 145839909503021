.cards-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70svh;
  color: white;
  margin: 0;
  font-family: Arial, sans-serif;

  .card-slider {
    position: relative;
    width: 80%;
    height: 90%;
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 80%;
    height: 100%;
    background: linear-gradient(to right, rgb(26, 41, 128), rgb(38, 208, 206));
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
    transition: transform 0.5s ease, opacity 0.5s ease;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    text-align: center;

    .company-desc {
      display: flex;
      flex-direction: column;
      align-content: center;
      height: 100%;
      justify-content: center;
    }
  }

  .prev-card {
    transform: translateX(-120%) rotateY(20deg);
    opacity: 0.7;
    z-index: 1;
  }

  .active-card {
    transform: translateX(0) rotateY(0);
    z-index: 2;
  }

  .next-card {
    transform: translateX(120%) rotateY(-20deg);
    opacity: 0.7;
    z-index: 1;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    font-size: 2em;
    cursor: pointer;
  }

  .nav-button,
  .prev-button {
    z-index: 9999;
  }

  .prev-button {
    left: -30px;
  }

  .next-button {
    right: -30px;
  }

  .nav-button:focus {
    outline: none;
  }
}

@media (min-width: 1200px) {
  .cards-slider-container {
    height: 60svh;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .cards-slider-container {
    height: 75svh;

    .card-slider {
      width: 90%;
      height: 100%;
    }

    .card {
      width: 90%;
      font-size: 1em;
    }

    .prev-card,
    .next-card {
      transform: translateX(-100%) rotateY(20deg);
    }

    .prev-button {
      left: -20px;
    }

    .next-button {
      right: -20px;
    }
  }
}

@media (max-width: 480px) {
  .cards-slider-container {
    height: 90svh;

    .card-slider {
      width: 80%;
      height: 100%;
    }

    .card {
      width: 100%;
      font-size: 0.9em;
    }

    .prev-card,
    .next-card {
      transform: translateX(-80%) rotateY(20deg);
    }

    .prev-button {
      left: -10px;
    }

    .next-button {
      right: -10px;
    }
  }
}

.experience-card {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .card-header {
    padding: 20px;
  }

  .card-title {
    color: #f8f9fa;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .card-text {
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: start;
  }

  ul {
    padding-left: 20px;
    list-style-type: none;
  }
}